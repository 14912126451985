<template>
	<!-- 畜牧列表 -->
	<div class="bullMarket-bull">
		<div class="el-content mb12">
			<a-input-search style="width: 400px;" v-model:value="search.key" 
				placeholder="请输入查询关键字" 
				enter-button 
				@search="getBullList(1,info.limit)" />
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'图片展示',dataIndex:'info',slots:{customRender:'info'}},
				{title:'联系人信息',dataIndex:'user',slots:{customRender:'user'}},
				{title:'价格',dataIndex:'price',slots:{customRender:'price'}},
				{title:'库存',dataIndex:'num'},
				{title:'最低购买',dataIndex:'min_buy'},
				{title:'是否公开',dataIndex:'is_public',slots:{customRender:'is_public'}},
				{title:'排序',dataIndex:'sort'},
				{title:'更新时间',dataIndex:'update_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #info="{record}">
					<div style="display: flex;align-items: center;">
						<a-image  style="width: 50px; height: 50px;margin-right: 8px;"
							:src="record.images[0]" />
						<span>{{record.title}}</span>
					</div>
				</template>
				<template #user="{record}">
					<div>{{record.contact_name}}</div>
					<div>{{record.contact_mobile}}</div>
				</template>
				<template #price="{record}">
					<div>售价:￥{{record.price}}</div>
					<div>定金:￥{{record.deposit_price}}</div>
				</template>
				<template #is_public="{record}">
					<a-tag color="#00CCCC" v-if="record.is_public == 1">公开</a-tag>
					<a-tag color="#999" v-if="record.is_public == 0">未公开</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<router-link :to="{path:'/adhibition/bullMarket/bull_edit',query:{id:record.id}}">
							<kd-button type="primary" title="编辑" icon="ri-edit-line"></kd-button>
						</router-link>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" @click="delBull([record.id])"></kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBullList(info.page,e)}"
					@change="(e)=>{getBullList(e,info.limit)}"
				/>
			</div>
		</div>
		
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{
	setup(){
		const state = reactive({
			search:{ key:'' },
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		getBullList(1,state.info.limit)
		function getBullList(page,limit){
			bullModel.getBull(page,limit,state.search,res=>state.info = {limit,...res})
		}

		const delBull = id=>bullModel.delBull(id,()=>getBullList(state.info.page,state.info.limit))

		return{
			...toRefs(state),
			getBullList,
			delBull
		}
	}
}
</script>

<style lang="scss">
</style>
